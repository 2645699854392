import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import NavigationBar from "../components/NavigationBar"
import Jumbotron from "../components/Jumbotron"
import styled from "styled-components"
import Footer from "../components/Footer"
import { Link } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { Helmet } from "react-helmet"

import "../styles/style.css"

const header = "Club Rides"
const pageSummary = "Details of our weekly club rides."

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const ContentDiv = styled.div`
  flex-grow: 1;
  text-align: justify;
  text-justify: auto;
`

const ClubRidesPage = ({ data }) => (
  <StyledContainer>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Our list of weekly club rides." />
      <title>Lomond Roads Cycling Club - Club Rides</title>
      <link rel="canonical" href="lomondroads.org/clubRides" />
    </Helmet>
    <NavigationBar />
    <Jumbotron header={header} pageSummary={pageSummary} />
    <ContentDiv>
      <Container>
        <Row>
          <Col>
            <p>
              All planned forthcoming Saturday rides are detailed in the list
              below.
            </p>

            <p>
              For those of you who are new to club cycling and are unsure of
              what to expect on one of our club rides, please consult our
              <a href="/Ridesafe.pdf"> Ridesafe Information Sheet</a> for
              further details, or alternatively
              <Link to="/contactUs"> contact us</Link>.
            </p>

            <p>
              Please note that we recommend a minimum age restriction of 14
              years of age to take part in these rides. It is preferable for
              anyone under the age of 16 to be accompanied with an adult. If
              this is not possible, then parental consent is required prior to
              taking part in any of our club rides. For further information
              regarding this, please contact us.
            </p>
            <h2>Rides List</h2>
          </Col>
        </Row>
        <Row>
          <Accordion allowMultipleExpanded allowZeroExpanded>
            {data.allGoogleSheet1Sheet.edges.map(obj => (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <b>{obj.node.date}</b> - {obj.node.ridename}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <b>Start Time - </b>
                  {obj.node.time}
                  <br />
                  <b>Route - </b>
                  {obj.node.route}
                  <br />
                  <b>Distance - </b>
                  {obj.node.distance} Miles
                  <br />
                  <b>Grade - </b>
                  {obj.node.grade}
                  <br />
                  <b>Leader - </b>
                  {obj.node.leader}
                  <br />
                  <b>Cafe Stop - </b>
                  {obj.node.cafestop}
                  <br />
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Row>
      </Container>
    </ContentDiv>
    <Footer />
  </StyledContainer>
)

export default ClubRidesPage

export const query = graphql`
query clubRides {
  allGoogleSheet1Sheet {
    edges {
      node {
        ridename
        date
        time
        route
        distance
        grade
        leader
        cafestop
      }
    }
  }
}
`
